import fetch from '../utils/fetch.js'

// 支付方式列表
export function bankList(query) {
  return fetch({
    url: '/api/pay/list',
    method: 'post',
    data: query
  })
}
// 支付方式编辑
export function payUpdate(query) {
  return fetch({
    url: '/api/pay/update',
    method: 'post',
    data: query
  })
}
// 更改状态（代收）
export function updateStatus(query) {
  return fetch({
    url: '/api/pay/updateStatus',
    method: 'post',
    data: query
  })
}
// 更改代付状态
export function updatePayAnother(query) {
  return fetch({
    url: '/api/pay/updatePayAnother',
    method: 'post',
    data: query
  })
}
export function adminList(query) {
  return fetch({
    url: '/api/admin/list',
    method: 'post',
    data: query
  })
}
export function payAdd(query) {
  return fetch({
    url: '/api/pay/add',
    method: 'post',
    data: query
  })
}
// 删除支付方式
export function payDelete(query) {
  return fetch({
    url: '/api/pay/delete',
    method: 'post',
    data: query
  })
}
