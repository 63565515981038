
<!--系统用户管理-->
<template>
  <div class="main-container">
    <el-form
      :model="form"
      class="form-style"
      size="mini"
      :inline="true">


      <!--输入框-->
      <el-form-item label="用户账号">
        <el-input v-model="form.value" placeholder="请输入用户账号"></el-input>
      </el-form-item>
      <el-form-item label="联系手机">
        <el-input v-model="form.value" placeholder="请输入联系手机"></el-input>
      </el-form-item>
      <el-form-item label="联系邮箱">
        <el-input v-model="form.value" placeholder="请输入联系邮箱"></el-input>
      </el-form-item>
      <el-form-item label="使用状态">
        <el-select v-model="form.value" placeholder="请选择使用状态">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="登录时间">
        <el-date-picker
          v-model="form.startTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择登录时间">
        </el-date-picker>
      </el-form-item>



      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">

          <el-table-column  label="用户账号" prop="account" align="center"></el-table-column>
          <el-table-column  label="联系手机" prop="" align="center" min-width="120"></el-table-column>
          <el-table-column  label="登录次数" prop="" align="center"></el-table-column>
          <el-table-column  label="使用状态" prop="" align="center">
            <template slot-scope="scope">
              <span :class="{'on': scope.row.status == 0, 'off': scope.row.status == -1}">{{scope.row.status == 0 ? '使用中' : '禁用'}}</span>
            </template>
          </el-table-column>
          <el-table-column  label="创建时间" prop="" align="center"></el-table-column>
          <el-table-column  label="最后登录时间" prop="" align="center"></el-table-column>

          <!--操作-->
          <el-table-column label="操作" fixed="right" align="center" width="250">
            <template slot-scope="scope">
              <el-button class="button button-small btnGreen" size="mini">密码</el-button>
              <el-button class="button button-small btnGreen" size="mini">编辑</el-button>
              <el-button class="button button-small btnYellow" size="mini"  >禁用</el-button>
              <el-button class="button button-small" size="mini" type="danger">删除</el-button>

            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

    </TableBody>
  </div>
</template>

<script>
import {adminList} from '../../../api/systemApi'
import TableMixin, {PageModelMixin} from '@/mixins/TableMixin'
import {GetDataMixin, DeleteItemsMixin} from '@/mixins/ActionMixin'

export default {
  name: '',
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      tableLoading: false,
      option: [
        {
          label: '全部',
          value: ''
        }
      ],

      tableData: [],
      form: {
        value: ''
      },
      pagination: {
        page: 1,
        size: 10

      },
      total: 0

    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    getTableList() {
      adminList({...this.pagination}).then(res => {
        this.tableData = res.data.result
        this.total = res.data.total
      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    }
  }

}
</script>

<style lang="scss" scoped>
.button-small {
  padding: 6px 12px;
}

.btnGreen {
  background: #009688;
  color: white
}
.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}
</style>

